export const state = () => ({
  sidebar: false,
  dataSummary: {},
  dataCheckbox: [],
})

export const mutations = {
  toggleSidebar(state) {
    state.sidebar = !state.sidebar
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_DATA_SUMMARY(state, payload) {
    state.dataSummary = payload
  },
  SET_DATA_CHECKBOX(state, payload) {
    state.dataCheckbox = payload
  },
}

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },

  loggedInUser(state) {
    return state.auth.user
  },

  getDataSummary(state) {
    return state.dataSummary
  },

  getDataCheckBox(state) {
    return state.dataCheckBox
  },
}
